import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../../components/LayoutFund"
import { theme } from "../../../components/Theme"

/** static sections */
import FundLeadershipTimSection from "../../../components/static-sections/FundLeadershipTimSection"

/** dynamic sections */
import HeroInvestImage from "../../../components/dynamic-sections/HeroInvestImage"
import HeroLeadershipText from "../../../components/dynamic-sections/HeroLeadershipText"
import HeroVideoFund from "../../../components/dynamic-sections/HeroVideoFund"

/** svg */
import NorhartBlackCircleIcon from "../../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const timFundLeadership: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/leadership/tim-libertini",
          name: "Tim Libertini | Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Tim Libertini | Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImage
        items={[
          {
            image: "/fund/leadership/tim-hero.png",
          },
        ]}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroLeadershipText
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="EXECUTIVE LEADERSHIP"
        title="Tim Libertini, Chief Financial Officer"
        formatSubTitle={() => (
          <>
            Tim enjoyed a wonderful childhood, spending his time hunting and fishing on the beautiful lakes of
            Minnesota. He was gifted with a talent for working with numbers, which he inherited from his math teacher
            father. At the young age of 14, Tim started his own business, which involved installing, refurbishing, and
            relocating playground equipment. He received his first small business loan when he was just 20 years old,
            and went on to acquire a competitor before his real estate career took off.
            <br />
            <br />
            Before joining Norhart, Tim worked at Dominium Apartments, a leading national developer, owner, and manager
            of affordable housing. He quickly rose from financial analyst to asset manager, and was responsible for
            overseeing a portfolio of more than 8,000 units across 60+ property developments nationwide, while achieving
            ambitious operational targets. As head of Dominium’s Dispositions Group, Tim handled complex project
            refinancings and strategically revived underperforming assets ahead of their sale. Tim joined Norhart’s
            management team four years ago as part of founder Mike’s ambitious plan to double the size of the company
            every five years by attracting and retaining top talent, standardizing processes, and increasing efficiency
            and profitability. With Tim’s help, Norhart exceeded this target, doubling in size for two consecutive
            years.
            <br />
            <br />
            Tim is currently responsible for Norhart’s accounting, administration, and asset management, and is in
            charge of capital-raising and deployment. He is a strategic, innovative, and extremely detail-oriented
            person who is relentless in his pursuit of results. Tim expertly aligns objectives and bridges communication
            between Norhart and its investors, executing impactful financial strategies that maximize returns. He
            leverages his expertise in ROI analysis, financing, contract negotiation, and project management to ensure
            optimal value and profitability for each project. Tim also spearheads Norhart’s impressive charitable
            initiatives, utilizing every opportunity to increase public awareness of upcoming projects and strategically
            partnering with brokers, lenders, and others in his broad financial network.
            <br />
            <br />
            Tim holds a degree in Finance and Real Estate from Herbert Business School at the University of Miami. He
            currently resides on a lake in Wisconsin with his fiancée Rachel and their two dogs. When he is not busy
            crunching numbers, Tim enjoys sport fishing in the summer and ice fishing in the freezing cold Wisconsin
            winter.
          </>
        )}
        subNote=""
        image=""
        imageAlt=""
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundLeadershipTimSection colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
export default timFundLeadership
